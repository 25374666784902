import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { ParseUserAgent } from "../../../Helpers/Devices/ParseUserAgent"
import { WindowSize } from "../../../Helpers/Devices/WindowSize"
import { Language } from "../../../Helpers/Devices/Language"
import { storageSet } from '../../../Helpers/Collector/StorageAction'
import { cookieSet } from '../../../Helpers/Collector/CookieAction'



export const getSites = createAsyncThunk(
    'Sites/getSites',
    async (data) => {

        const apiData = {
            browserName: ParseUserAgent.browserName,
            deviceType: ParseUserAgent.deviceType,
            fullBrowserVersion: ParseUserAgent.fullBrowserVersion,
            getUA: ParseUserAgent.getUA,
            mobileModel: ParseUserAgent.mobileModel,
            mobileVendor: ParseUserAgent.mobileVendor,
            osName: ParseUserAgent.osName,
            osVersion: ParseUserAgent.osVersion,
            language: Language()[0],
            windowSize: WindowSize(),
            clientId: data.clientId.key,
            sessionId: data.sessionId.key,
            sId: data.sId
        }

        const res = await axios.post(process.env.REACT_APP_ADDRESS + `Init/InitApp`, apiData);


        return res.data;
    })


export const SitesSlice = createSlice({
    name: 'Sites',
    initialState: {
        loading: true,
        translate: [],
        EItem:[]

    },
    extraReducers: (builder) => {


        builder.addCase(getSites.pending, (state, { payload }) => {
            state.loading = true
        })

        builder.addCase(getSites.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.translate = payload.translate
            state.EItem = payload.EItem
            const sid = {
                key: payload.sId
            }
            storageSet("sId", sid);
            cookieSet("csrf", payload.csrf);

            axios.defaults.headers.common["sId"] = payload.sId;
            axios.defaults.headers.common["csrf"] = payload.csrf;
            axios.defaults.headers.common["clientId"] = payload.clientId;
            axios.defaults.headers.common["sessionId"] = payload.sessionId;
            axios.defaults.headers.common["clientlanguage"] = payload.translate.lang;

        })


        builder.addCase(getSites.rejected, (state, { payload }) => {
            state.loading = false
        })

    }
})

export const SitesReducer = SitesSlice.reducer