import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from 'redux'
import { SitesReducer } from '../Components/App/Reducer/AppReducer'
import { LoginReducer } from '../Components/Login/Reducer/LoginReducer'

const reducer = combineReducers({
  Sites: SitesReducer,
  Login: LoginReducer

})
const store = configureStore({
  reducer,
})

export default store;