import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { storageSet } from '../../../Helpers/Collector/StorageAction'
import { setAuthorizationToken } from '../../../Helpers/setAuthorizationToken'



export const getLogin = createAsyncThunk(
    'Login/getLogin',
    async (data) => {

        const apiData = {
            email: data.email,
            password: data.password
        }

        const res = await axios.post(process.env.REACT_APP_ADDRESS + `Login/Auth`, apiData);
        return res.data;
    })


export const LoginSlice = createSlice({
    name: 'Login',
    initialState: {
        loading: true,
        auth: false,
        user: [],
        error:false

    },
    reducers: {
        LogoutReducer: (state) => {

            state.loading = false;
            state.auth = false;
            state.user = [];

            localStorage.removeItem("token");
            localStorage.removeItem("refreshToken");

        },
        RefershLoginReducer: (state, action) => {
            state.loading = false;
            state.auth = true;
            state.user = action.payload.user;


            storageSet("token", action.payload.token);

            //setAuthorizationToken(action.token);
        }
    },
    extraReducers: (builder) => {

        builder.addCase(getLogin.pending, (state, { payload }) => {
            state.loading = true;
            state.error = false;
        })

        builder.addCase(getLogin.fulfilled, (state, { payload }) => {
            state.loading = false;

            if (payload.status) {
                state.auth = true;
                state.user = payload.user;
                state.error = true;
                storageSet("token", payload.token);
                storageSet("refreshToken", payload.refreshToken);
                setAuthorizationToken(payload.token);

            } else {
                state.auth = false;
                state.error = true
                state.user = [];
            }
        })

        builder.addCase(getLogin.rejected, (state, { payload }) => {
            state.loading = false
        })

    }
})

export const { LogoutReducer, RefershLoginReducer } = LoginSlice.actions;



export const LoginReducer = LoginSlice.reducer