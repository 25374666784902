import React, { useState } from 'react';

import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    QuestionCircleOutlined,
    UserOutlined,
    DashboardOutlined,
    DingtalkOutlined,
    RocketOutlined,
    LoginOutlined,
    SettingOutlined
} from '@ant-design/icons';

import { Layout, Menu, theme } from 'antd';
import { Link } from 'react-router-dom';
import logo from '../../../Assets/img/logo.svg'
import "../../../Assets/css/main.css"
import { LogoutReducer } from '../../Login/Reducer/LoginReducer'
import { connect } from 'react-redux'
const { Header, Sider, Content } = Layout;



const Layouts = (props) => {


    const { companyTitle,  name, surname } = props.Login.user

    const [collapsed, setCollapsed] = useState(false);
    const {
        token: { colorBgContainer },
    } = theme.useToken();



    const Logout = () => {

        props.LogoutReducer()
    }


    return (

        <>
            <Layout>
                <Sider trigger={null} collapsible collapsed={collapsed}
                    style={{
                        overflow: 'auto',
                        
                        minHeight:'100vh',


                    }}
                >
                    <div style={{ height: 32, margin: 16, background: 'rgba(255, 255, 255, 0.2)' }} />
                    <div className='menu'>
                        <div className="companyInfo">
                            <div className="avatar">
                                <img src={logo} alt="icon" />
                            </div>
                            {!collapsed &&
                                <div className="infos">
                                    <div className="companyName">{companyTitle}</div>
                                    <div className="nameSurname">{name} {surname}</div>
                                    <span className="badge bg-primary">Approved</span>
                                </div>
                            }
                        </div>
                    </div>
                    <Menu
                        theme="dark"
                        mode="inline"
                        defaultSelectedKeys={['1']}
                        items={[
                            {
                                key: '1',
                                icon: <DashboardOutlined />,
                                label: <Link to="/Dashboard">DASHBOARD</Link>,

                            },
                            {
                                key: '2',
                                icon: <RocketOutlined />,
                                label: <Link to="/Card">E-CARD</Link>,
                            },

                            {
                                key: '3',
                                icon: <DingtalkOutlined />,
                                label: <Link to="/Theme">THEME</Link>,
                            },
                            {
                                key: '5',
                                icon: <UserOutlined />,
                                label: <Link to="/Accounts">ACCOUNT</Link>,
                            },
                            {
                                key: '6',
                                icon: <SettingOutlined />,
                                label: "SETTINGS",
                                children: [{
                                    key: '61',
                                    label: <Link to="/Settings/Company">COMPANY</Link>,
                                }, {
                                    key: '62',
                                    label: <Link to="/Settings/Bank">BANKS</Link>,
                                }, {
                                    key: '63',
                                    label: <Link to="/Settings/Accounting">ACCOUNTING</Link>,
                                }, {
                                    key: '64',
                                    label: <Link to="/Settings/Address">ADDRESS</Link>,
                                }]
                            },

                            {
                                key: '7',
                                icon: <QuestionCircleOutlined />,
                                label: <Link to="/Logout">HELP</Link>,
                            },
                            {
                                key: '8',
                                icon: <LoginOutlined />,
                                label: "LOGOUT",
                                onClick: () => {
                                    Logout()
                                }
                            },
                        ]}
                    />
                </Sider>
                <Layout className="site-layout">
                    <Header
                        style={{
                            padding: 0,
                            background: colorBgContainer,
                        }}
                    >
                        {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                            className: 'trigger',
                            onClick: () => setCollapsed(!collapsed),
                        })}
                    </Header>
                    <Content
                        style={{

                            paddingLeft: 24,
                            paddingRight: 24

                        }}
                    >
                        {props.children}
                    </Content>
                </Layout>
            </Layout>


        </>





    );
};


const mapStateToProps = (state) => ({
    Login: state.Login
})

const mapDispatchToProps = {
    LogoutReducer
}

export default connect(mapStateToProps, mapDispatchToProps)(Layouts);
