
import axios from 'axios';
import { LogoutReducer, RefershLoginReducer } from "../Components/Login/Reducer/LoginReducer"
import jwt_decode from "jwt-decode";


let store;

export const injectStore = _store => {
    store = _store
}

export const setAuthorizationToken = (token) => {

    let jwts = ""

    try {

        jwts = jwt_decode(token);

    } catch (e) {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        console.log(e);
    }



    if (jwts === undefined && jwts === "undefined" && jwts === "") {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");

        store.dispatch(LogoutReducer())
    }


    if (jwts === undefined && jwts === "undefined" && jwts === "") {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }

    axios.interceptors.request.use(
        (config) => {
            const accessToken = localStorage.getItem("token");
            if (accessToken) {
                config.headers["Authorization"] = `Bearer ${accessToken}`;
            }
            return config;
        },
        (error) => {
            Promise.reject(error);
        }
    );

    axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        delete axios.defaults.headers.common["Authorization"];
        const originalRequest = error.config
        let refreshToken = localStorage.getItem("refreshToken");


        if (typeof error.response !== "undefined") {
            if (refreshToken && error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                return axios.post(process.env.REACT_APP_ADDRESS + `Token/RefreshToken`, { refreshToken: refreshToken })
                    .then((res) => {

                        if (res.status === 200 && res.data.return) {
                            localStorage.setItem("token", res.data.token);
                            axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.token}`;
                            return axios(originalRequest);
                        } else {
                            localStorage.removeItem("token");
                            localStorage.removeItem("refreshToken");

                            store.dispatch(LogoutReducer())
                        }
                    });
            } else {
                localStorage.removeItem("token");
                localStorage.removeItem("refreshToken");

                store.dispatch(LogoutReducer())              // window.location.reload();
            }
        } else {

        }

        return Promise.reject(error);
    });


}


export const getRefreshPageTokenSet = () => {

    let refreshToken = localStorage.getItem("refreshToken");

    if (refreshToken !== null) {
        return axios.post(process.env.REACT_APP_ADDRESS + `Token/RefreshToken`, { refreshToken: refreshToken })
            .then((res) => {
                if (res.status === 200) {


                    if (res.data.return) {
                 
                        store.dispatch(RefershLoginReducer(res.data))
                        axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.token}`;
                    } else {
                        localStorage.removeItem("token");
                        localStorage.removeItem("refreshToken");

                        store.dispatch(LogoutReducer())
                    }
                }
            });
    } else {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");

        store.dispatch(LogoutReducer())
    }

}

